<template>
  <div class="discover-container">

    <!-- hander -->
    <div class="userinfo-box" :style="{
      background: verConfig.mineBG ? `url(${verConfig.mineBG})` : '',
    }">
      <div class="person-box">

        <div class="editinfo">

          <div class="left">
            <van-image round class="avatar" fit="cover"
              :src="userList.userimg ? `${verConfig.url}${userList.userimg}` : userImg" />
            <div class="personal" v-if="userInfo">
              <div class="name" :class="{ 'yellow-color': !$isLinkGame }">
                {{ userList.username }}
              </div>
              <div class="use">
                <span class="ID" :class="{ 'yellow-color': !$isLinkGame }">ID：{{ userList.id }}</span>
              </div>
              <div class="vip" :class="{ 'yellow-color': !$isLinkGame }">
                {{ userList.rank_title }}
              </div>
            </div>
            <div v-else style="font-size: 18px" :class="{ 'yellow-color': !$isLinkGame }">
              {{ $t('login.pleaseLogin') }}
            </div>
          </div>
          <div style="margin-top: -100px; margin-right: 16px;">
            <van-icon name="orders-o" @click="handelmarketing" />
          </div>
        </div>
      </div>
    </div>
    <!-- center -->
    <div>
      <van-form @submit="onSubmit">

        <!-- 	ref no：	 -->
        <van-field v-model="refNo" name="refNo" :label="$t('discover.refNo')" :placeholder="$t('discover.pleaseRefNo')"
          :rules="[{ required: true, message: $t('discover.pleaseRefNo') }]" />

        <!-- Recharge amount: -->
        <van-field v-model="amount" name="amount" :label="$t('discover.rechargeAmount')"
          :placeholder="$t('discover.pleaseRechargeAmount')"
          :rules="[{ required: true, message: $t('discover.pleaseRechargeAmount') }]" />

        <!-- Remarks： -->
        <van-field v-model="remark" name="remark" rows="5" autosize :label="$t('discover.remark')" type="textarea"
          :placeholder="$t('discover.pleaseRemark')"
          :rules="[{ required: true, message: $t('discover.pleaseRemark') }]" />
        <div class="button-submit">
          <van-button :loading="isLoading" color="#ff0000" round block type="info" native-type="submit">{{
            $t('Discover_btn')
          }}</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>

import userImg from '@/assets/images/index_user/user.png'
import { userinfo } from '@/api/use.js'
import { getItem } from '@/utils/storage'
import { setMarketing } from '@/api/sale'
import { Toast } from "vant"
import router from '@/router';
export default {
  name: 'DiscoverTwo',
  components: {
    Toast
  },
  props: {},
  data() {
    return {
      userList: {},
      userImg: userImg,
      userInfo: null,
      // 表单
      refNo: '',
      amount: '',
      remark: '',
      isLoading: false
    }
  },
  computed: {},
  watch: {},
  created() {
    this.getUserInfo()
    this.userInfo = getItem('TOUTIAO_USER')
  },
  mounted() { },
  methods: {
   
    handelmarketing() {
      router.push('/marketingList')
    },
    async onSubmit(values) {
      this.isLoading = true
      try {
        const { msg, data } = await setMarketing({
          title: values.refNo,
          amount: values.amount,
          remark: values.remark,
        });
        Toast(msg);
        this.isLoading = false
        this.refNo = '',
          this.amount = '',
          this.remark = ''

      } catch (error) {
        this.refNo = '',
          this.amount = '',
          this.remark = ''
        this.isLoading = false
        Toast(error.msg)
      }
    },

    async getUserInfo() {
      const {
        data: { ret, data }
      } = await userinfo()

      if (ret === 1) {
        this.userList = data
      }
    },
  }
}
</script>

<style lang="less" scoped>
.discover-container {
  .van-nav-bar {
    width: 100%;
    height: 100px;
    position: fixed;
  }
}

.userinfo-box {
  position: relative;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 290px;
  // height: 360px;
  background-image: url('../../../assets/images/index_user/background.png');
  //   no-repeat;
  // background: #0083e1;
  background-size: cover;
  display: flex;

  // align-items: center;
  .person-box {
    width: 100%;
    position: relative;

    .editinfo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;
      margin-top: 80px;

      .left {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 22px;

        .avatar {
          width: 118px;
          height: 118px;
          margin-right: 32px;
        }

        .personal {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          font-family: "PingFang-Medium";
          font-size: 24px;
          color: #fffefe;

          .gender,
          .start {
            margin-left: 5px;
          }

          .gender {
            display: inline-block;
          }

          .use {
            margin: 15px 0;
          }

          .name {
            font-size: 30px;
          }

          .vip {
            width: 200px;
            height: 45px;
            border-radius: 10px;
            line-height: 45px;
            text-align: center;
            font-size: 20px;
            background-color: rgba(255, 255, 255, 0.4);
          }
        }
      }

      .logout {
        margin-right: 34px;
      }
    }

    .text {
      margin-left: 22px;
      margin-top: 22px;
      font: 20px "PingFang-Medium";
      color: rgba(237, 245, 245, 0.5);
    }

    .new-img-com {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 30px;
      box-sizing: border-box;
      position: absolute;
      right: 30px;
      bottom: 6px;
      height: 1.33333rem;

      >img {
        // height: 100px;
        height: 80%;
      }
    }

    .right_bar {
      position: absolute;
      left: 22px;
      top: 10px;
      display: flex;
      align-items: center;

      .right {
        /deep/ .van-badge {
          background: transparent !important;
          border: none;

          .text {
            color: #fff;
            font: 28px "PingFang-Medium";
          }
        }
      }

      img {
        width: calc(26rem / 16);
        height: calc(26rem / 16);
        margin-left: calc(10rem / 16);
      }

      .balance {
        color: #fff;
        margin-top: calc(10rem / 16);
      }
    }
  }



}

.button-submit {
  margin: 80px 30px 30px 30px;

}

/deep/ .van-field--error .van-field__control::placeholder {
  color: #c8c9d6 !important;
}

/deep/ .van-cell__value {
  background-color: #f4f8f9;
  padding-left: 20px;
}
</style>
